function createObject() {
  let example = {
    id: "PfadeAlleszaehlen",
    workOrder: `Wählen Sie aus den vier Diagnoseaufgaben zunächst genau zwei aus. Betrachen Sie dann die Lösungen und entscheiden Sie sich für genau eine - möglichst passgenaue - Förderaufgabe.`,
    image: require("@/assets/nzr/Pfade/Alleszaehlen/01.png"),
    imageSizePercent: 70,
    mediaDescription: 'Jessica bearbeitet die Aufgabe "Wie lautet das Doppelte von 8?"',
    pupilName: "Jessica",
    elements: [
      [
        {
          selectCount: 2,
          typeText: "Diagnoseaufgabe",
          question: "Wählen Sie nun <u>genau zwei</u> weitere Diagnoseaufgaben aus, zu denen Sie die Lösung sehen möchten, um die Diagnose zu vertiefen.",
          diagnostics: [
            {
              id: 1,
              taskImage: require("@/assets/nzr/Pfade/Alleszaehlen/02.png"),
              solutionImage: require("@/assets/nzr/Pfade/Alleszaehlen/03.png"),
            },
            {
              id: 2,
              taskImage: require("@/assets/nzr/Pfade/Alleszaehlen/04.png"),
              solutionImage: require("@/assets/nzr/Pfade/Alleszaehlen/05.png"),
            },
            {
              id: 3,
              taskImage: require("@/assets/nzr/Pfade/Alleszaehlen/06.png"),
              solutionImage: require("@/assets/nzr/Pfade/Alleszaehlen/07.png"),
            },
            {
              id: 4,
              taskImage: require("@/assets/nzr/Pfade/Alleszaehlen/08.png"),
              solutionImage: require("@/assets/nzr/Pfade/Alleszaehlen/09.png"),
            },
          ],
        },
      ],
      [
        {
          selectCount: 1,
          typeText: "Förderaufgabe",
          question:
            "Wählen Sie nun <u>eine</u> passende Förderaufgabe aus, die zu Ihren gewählten Diagnoseaufgaben passt.",
          diagnostics: [
            {
              id: 1,
              taskImage: require("@/assets/nzr/Pfade/Alleszaehlen/10.png"),
            },
            {
              id: 2,
              taskImage: require("@/assets/nzr/Pfade/Alleszaehlen/11.png"),
            },
            {
              id: 3,
              taskImage: require("@/assets/nzr/Pfade/Alleszaehlen/12.png"),
            },
            {
              id: 4,
              taskImage: require("@/assets/nzr/Pfade/Alleszaehlen/13.png"),
            },
          ],
        },
      ],
    ],
  };

  const DA_1 = "Diagnoseaufgabe 1 zeigt, dass Jessica die Anzahl ermittelt, indem sie von 1 beginnend alle Plättchen zählt, bis sie das Ergebnis erreicht hat (Alleszählen). Ihr Ergebnis ist korrekt, allerdings ist diese Vorgehensweise zeitaufwändig und kann nicht unmittelbar auf größere Summanden übertragen werden. ";

  const DA_2 = "Diagnoseaufgabe 2 lässt erkennen, dass Jessica die Summe korrekt bestimmen kann, hierfür jedoch zählend vorgeht (Alleszählen). Sie zählt zunächst, bis sie den ersten Summanden erreicht (hier durch Unterstreichen markiert) und zählt dann um den zweiten Summanden weiter, bis die Summe erreicht wurde. ";

  const DA_3 = "Diagnoseaufgabe 3 wurde von Jessica korrekt gelöst. Aussagen über ihre Vorgehensweise können jedoch nicht getroffen werden. ";

  const DA_4 = "Die eingezeichneten Punkte in Diagnoseaufgabe 4 weisen auf eine zählende Vorgehensweise hin (Alleszählen). Das Ergebnis ist korrekt, allerdings wurden Zahl- und Aufgabenbeziehungen am Zahlenstrahl außer Acht gelassen, da in Einerschritten gezählt wurde. ";

  const FA_1 = "Förderaufgabe 1 spricht die Teil-Ganzes-Beziehung an. Hierbei kann Jessica die möglichen Zerlegungen der 10 auf systematische Weise ergründen, beispielsweise indem sukzessive die Farbe eines Plättchens vertauscht wird (operative Veränderung). Zugleich kann die Konstanz der Summe erfahren werden. Sie muss somit nicht auf zählende Strategien zurückgreifen, sondern kann beide Summanden kardinal als Menge von Plättchen betrachten. ";

  const FA_2 = "Förderaufgabe 2 ist strukturell ähnlich zur zweiten und dritten Diagnoseaufgabe. Es ist nicht zu erwarten, dass Jessica in dieser einen Erkenntniszuwachs erzielen kann. ";

  const FA_3 = "Förderaufgabe 3 versucht, Aufgabenbeziehungen in den Blick zu nehmen. Jessica kann durch Ausnutzung benachbarter, „einfacherer“ Aufgaben (z.B. 7+7 [Gegensinniges Verändern] oder 10+6 [Hilfsaufgabe]) diese Additionsaufgabe lösen, ohne auf zählende Vorgehensweisen zurückgreifen zu müssen. Ob ihr dies ohne Materialunterstützung gelingt, ist jedoch fraglich. ";

  const FA_4 = "Förderaufgabe 4 vertieft Jessicas Verständnis für die Struktur des Zahlenstrahls, indem sie angeregt wird, den ersten Summanden zu erkennen. Dies kann dazu anregen, das Alleszählen durch das Weiterzählen als Strategie abzulösen. Nichtzählende Rechenstrategien werden somit jedoch nicht angeregt. Der Vergleich und die Reflexion von Samiras Vorgehensweise kann jedoch dazu beitragen, die Vorteile flexiblen Rechnens gegenüber zählender Rechenstrategien zu ergründen. ";

  const PASSUNG_SEHR_GUT = `
        Diese Förderaufgabe passt sehr gut zur Diagnose.
        <br />
        `;
    const PASSUNG_GUT = `
        Diese Förderaufgabe passt ganz gut zur Diagnose.
        <br />
        `;
    const PASSUNG_SCHLECHT = `
        Diese Förderaufgabe hätten wir nach der Diagnose eher nicht gewählt.
        <br />
        `;

    const PASSUNG_SEHR_SCHLECHT = `
        Diese Aufgabe hätten wir nicht gewählt.
        <br />
        `;

    example.responses = [{
        id: '112',
            text: PASSUNG_SEHR_GUT + DA_1 + DA_2 + FA_1
        },
        {
            id: '113',
            text: PASSUNG_SEHR_GUT + DA_1 + DA_3 + FA_1
        },
        {
            id: '114',
            text: PASSUNG_SEHR_GUT + DA_1 + DA_4 + FA_1
        },
        {
            id: '123',
            text: PASSUNG_SEHR_GUT + DA_2 + DA_3 + FA_1
        },
        {
            id: '124',
            text: PASSUNG_SEHR_GUT + DA_2 + DA_4 + FA_1
        },
        {
            id: '134',
            text: PASSUNG_SEHR_GUT + DA_3 + DA_4 + FA_1
        },

        {
            id: '212',
            text: PASSUNG_SEHR_SCHLECHT + DA_1 + DA_2 + FA_2
        },
        {
            id: '213',
            text: PASSUNG_SEHR_SCHLECHT + DA_1 + DA_3 + FA_2
        },
        {
            id: '214',
            text: PASSUNG_SEHR_SCHLECHT + DA_1 + DA_4 + FA_2
        },
        {
            id: '223',
            text: PASSUNG_SEHR_SCHLECHT + DA_2 + DA_3 + FA_2
        },
        {
            id: '224',
            text: PASSUNG_SEHR_SCHLECHT + DA_2 + DA_4 + FA_2
        },
        {
            id: '234',
            text: PASSUNG_SEHR_SCHLECHT + DA_3 + DA_4 + FA_2
        },

        {
            id: '312',
            text: PASSUNG_GUT + DA_1 + DA_2 + FA_3
        },
        {
            id: '313',
            text: PASSUNG_GUT + DA_1 + DA_3 + FA_3
        },
        {
            id: '314',
            text: PASSUNG_SCHLECHT + DA_1 + DA_4 + FA_3
        },
        {
            id: '323',
            text: PASSUNG_GUT + DA_2 + DA_3 + FA_3
        },
        {
            id: '324',
            text: PASSUNG_GUT + DA_2 + DA_4 + FA_3
        },
        {
            id: '334',
            text: PASSUNG_GUT + DA_3 + DA_4 + FA_3
        },
        {
            id: '412',
            text: PASSUNG_SCHLECHT + DA_1 + DA_2 + FA_4
        },
        {
            id: '413',
            text: PASSUNG_SCHLECHT + DA_1 + DA_3 + FA_4
        },
        {
            id: '414',
            text: PASSUNG_GUT + DA_1 + DA_4 + FA_4 
        },
        {
            id: '423',
            text: PASSUNG_SCHLECHT + DA_2 + DA_3 + FA_4
        },
        {
            id: '424',
            text: PASSUNG_GUT + DA_2 + DA_4 + FA_4 
        },
        {
            id: '434',
            text: PASSUNG_GUT + DA_3 + DA_4 + FA_4
        },
]

  return example;
}

export default createObject();
